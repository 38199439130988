import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Layout from "../components/Layout"

const NotFoundPage = ({location}) => (
  <Layout location={location}>
    <Wrapper>
      <div>
        <h1>404</h1>
        <h3>Page not found</h3>
        <Link to="/" className="btn">
          home
        </Link>
      </div>
    </Wrapper>
  </Layout>
)

const Wrapper = styled.main`
  margin-top: -5rem;
  background: var(--clr-primary-8);
  min-height: calc(100vh - 5rem);
  display: grid;
  place-items: center;
  padding: 0 1rem;
  div {
    text-align: center;
    color: var(--clr-white);
  }
  h1 {
    font-size: 5rem;
  }
  h3 {
    text-transform: none;
    margin-top: 1.5rem;
    margin-bottom: 1.75rem;
  }
`

export default NotFoundPage
